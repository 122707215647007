/* eslint-disable quote-props */
import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import InfosHeader from '../components/Infos/InfosHeader'
import { StaticImage } from 'gatsby-plugin-image'
import { useForm, ValidationError } from '@formspree/react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Form = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [state, handleSubmit] = useForm('mzbnzeyp')

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    const recaptchaToken = await executeRecaptcha('submit')
    handleSubmit({
      'g-recaptcha-response': recaptchaToken,
      'prenom': e.target.prenom.value,
      'nom': e.target.nom.value,
      'telephone': e.target.telephone.value,
      'email': e.target.email.value,
      'event-name': e.target['event-name'].value,
      'event-date': e.target['event-date'].value,
      'infos': e.target.infos?.value
    })
  }
  if (state.succeeded) {
    return (
      <div className='scene container content pb-12 text-center'>
        <h3 className='!my-24 !text-center'>Merci! Nous avons bien reçu votre demande et nous vous contacterons sous peu.</h3>
        <form action="/"><button type='submit'>Retour</button></form>
      </div>
    )
  }

  return (
    <form onSubmit={handleFormSubmit} className='block' data-netlify-recaptcha='true'>

    {/* PRÉNOM */}
    <div className="scene-contact-form-group">
        <label htmlFor="prenom">Prénom <span>*</span></label>
        <input id="prenom" type="text" name="prenom" required />
        <ValidationError prefix="Prénom" field="prenom" errors={state.errors} />
    </div>

    {/* NOM */}
    <div className="scene-contact-form-group">
        <label htmlFor="nom">Nom <span>*</span></label>
        <input id="nom" type="text" name="nom" required />
        <ValidationError prefix="Nom" field="nom" errors={state.errors} />
    </div>

    {/* TÉLÉPHONE */}
    <div className="scene-contact-form-group">
        <label htmlFor="telephone">Numéro de téléphone <span>*</span></label>
        <input id="telephone" type="tel" name="telephone" required />
        <ValidationError prefix="Numéro de téléphone" field="telephone" errors={state.errors} />
    </div>

    {/* EMAIL */}
    <div className="scene-contact-form-group">
        <label htmlFor="email">Adresse courriel <span>*</span></label>
        <input id="email" type="email" name="email" required />
        <ValidationError prefix="Adresse courriel" field="email" errors={state.errors} />
    </div>

    {/* NOM DE L'ÉVÈNEMENT */}
    <div className="scene-contact-form-group">
        <label htmlFor="event-name">Nom de l'évènement <span>*</span></label>
        <input id="event-name" type="text" name="event-name" required />
        <ValidationError prefix="Nom de l'évènement" field="event-name" errors={state.errors} />
    </div>

    {/* DATE DE L'ÉVÈNEMENT */}
    <div className="scene-contact-form-group">
        <label htmlFor="event-date">Date de l'évènement <span>*</span></label>
        <input id="event-date" type="date" name="event-date" required />
        <ValidationError prefix="Date de l'évènement" field="event-date" errors={state.errors} />
    </div>

    {/* INFORMATIONS ADDITIONNELLES */}
    <div className="scene-contact-form-group">
        <label htmlFor="infos">Informations additionnelles</label>
        <textarea id="infos" name="infos" rows="4" cols="100"></textarea>
        <ValidationError prefix="Informations additionnelles" field="autres" errors={state.errors} />
    </div>

    {/* reCAPTCHA */}
    <input type="hidden" name="g-recaptcha-response" />

    {/* SUBMIT BUTTON */}
    <button type="submit" disabled={state.submitting}>Soumettre</button>
</form>
  )
}

const LocationScene = () => {
  const metaTitle = 'Scène disponible en location | Le Festif! de Baie-Saint-Paul'
  const metaDescription = "La magnifique et polyvalente scène Stageline 100 (SL-100) du Festif! est à louer. Facile d'utilisation, elle se déploie en quelques minutes."
  const metaKeywords = 'festival, musique, événement, spectacle, québec, scène, location'

  const recaptchaKey = '6Lec9VQpAAAAAPBH8D9YvQl4z2dlXS139FiuG_2L'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle}
                    description={metaDescription}
                    keywords={metaKeywords}
                    imageUrl={'https://images.prismic.io/le-festif-ca/a62f4016-228a-46b8-81d5-2fa40de831d6_og_scene.png'}
                    path='location-scene' />
      <InfosHeader title="SCÈNE DISPONIBLE EN LOCATION" />
      <div className="container content pb-12">

        <div className="max-w-[2048px] max-h-[767px]">
          <StaticImage src="../images/location-scene.png" alt="Ambiance du Parvis" imgClassName="rounded-3xl"/>
        </div>

        <div>
          <p className='my-12'>Nous avons une magnifique et polyvalente scène Stageline 100 (SL-100) facile d'utilisation qui se déploie en quelques minutes. Nous nous engageons à adapter notre prix en fonction de la demande et des réalités de l'événement.</p>
          <h2 className='mb-4'>Informations de la STAGELINE SL100 :</h2>
          <ul>
            <li>Plancher : 7.3m x 6.1m up to 12.2m x 9,8m</li>
            <li>Capacité d'accrochage : 5,170 kg</li>
          </ul>
          <h2 className='pt-6'>Contactez-nous pour réserver</h2>
          <div className='scene md:px-[60px] px-[10px]'>
            <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} scriptProps={{ async: true }}>
              <Form />
            </GoogleReCaptchaProvider>
          </div>

        </div>
      </div>
    </Wolflayout>
  )
}

export default LocationScene
